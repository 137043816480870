

































import {Component, Vue} from 'vue-property-decorator';
import WorkspacePage from "@/components/layout/WorkspacePage.vue";

@Component({
  components: {WorkspacePage},
})
export default class SubscriptionSuccessPage extends Vue {

  get currentSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

}
